.two-c-layout {
  > * {
    margin-bottom: 2rem;
  }
}

.three-c-layout {
  > * {
    margin-bottom: 2rem;

    &:nth-child(1) {
      -ms-grid-column: 1;
    }

    &:nth-child(2) {
      -ms-grid-column: 2;
    }

    &:nth-child(3) {
      -ms-grid-column: 3;
    }

    &:nth-child(4) {
      -ms-grid-column: 1;
      -ms-grid-row: 2;
    }

    &:nth-child(5) {
      -ms-grid-column: 2;
      -ms-grid-row: 2;
    }

    &:nth-child(6) {
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }

    &:nth-child(7) {
      -ms-grid-column: 1;
      -ms-grid-row: 3;
    }

    &:nth-child(8) {
      -ms-grid-column: 2;
      -ms-grid-row: 3;
    }

    &:nth-child(9) {
      -ms-grid-column: 3;
      -ms-grid-row: 3;
    }

    &:nth-child(10) {
      -ms-grid-column: 1;
      -ms-grid-row: 4;
    }

    &:nth-child(11) {
      -ms-grid-column: 2;
      -ms-grid-row: 4;
    }

    &:nth-child(12) {
      -ms-grid-column: 3;
      -ms-grid-row: 4;
    }

    &:nth-child(13) {
      -ms-grid-column: 1;
      -ms-grid-row: 5;
    }

    &:nth-child(14) {
      -ms-grid-column: 2;
      -ms-grid-row: 5;
    }

    &:nth-child(15) {
      -ms-grid-column: 3;
      -ms-grid-row: 5;
    }
  }
}

.four-c-layout {

  > * {
    &:first-child {
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }
  &.layout--square {
    &::before {
      content: '';
      width: 0;
      padding-bottom: 100%;
      grid-row: 1 / 1;
      grid-column: 1 / 1;
    }
  }
}

@include media-breakpoint-up(sm) {
  .four-c-layout-2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 -1rem;

    > * {
      padding: 0 1rem;
      width: 50%;
    }
  }

  .four-c-layout {
    display: grid;
    grid-template-columns: 6fr 6fr;

    display: -ms-grid;
    -ms-grid-columns: 6fr 6fr;

    &:not(.row-height-auto) {
      -ms-grid-rows: 6fr 6fr;
    }

    &.row-height-auto {
      -ms-grid-rows: auto auto;
    }

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
    }

    &.c-gap {
      grid-column-gap: 2rem;
      -ms-grid-columns: 6fr 2rem 6fr;

      > * {

        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-child(3) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
        }

        &:nth-child(4) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }

        &:nth-child(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-child(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }
      }
    }
  }

  .five-c-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-child(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-child(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
    }
  }

  .six-c-layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1.875rem;

    display: -ms-grid;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr 1.875rem 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
    }
  }
}

@include media-breakpoint-up(md) {

  .two-c-layout {
    display: grid;
    grid-template-columns: 6fr 6fr;

    display: -ms-grid;
    -ms-grid-columns: 6fr 6fr;

    &:not(.row-height-auto) {
      -ms-grid-rows: 6fr 6fr;
    }

    &.row-height-auto {
      -ms-grid-rows: auto auto;
    }

    > * {
      margin-bottom: 0;

      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }

      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }

      &:nth-child(3) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }

      &:nth-child(4) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }

      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }

      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 3;
      }
    }

    &.c-gap {
      grid-column-gap: 2rem;
      -ms-grid-columns: 6fr 2rem 6fr;

      > * {

        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-child(3) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
        }

        &:nth-child(4) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }

        &:nth-child(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }

        &:nth-child(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }
      }
    }
  }
}

@include media-breakpoint-up(lg){
  .three-c-layout {
    display: grid;
    grid-template-columns: 6fr 6fr 6fr;

    display: -ms-grid;
    -ms-grid-columns: 6fr 6fr 6fr;

    &:not(.row-height-auto) {
      -ms-grid-rows: 6fr 6fr;
    }

    &.row-height-auto {
      -ms-grid-rows: auto auto;
    }

    > * {
      margin-bottom: 0;

      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(5) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(6) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(8) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(9) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(10) {
        -ms-grid-column: 1;
        -ms-grid-row: 4;
      }
      &:nth-child(11) {
        -ms-grid-column: 3;
        -ms-grid-row: 4;
      }
      &:nth-child(12) {
        -ms-grid-column: 5;
        -ms-grid-row: 4;
      }
      &:nth-child(13) {
        -ms-grid-column: 1;
        -ms-grid-row: 5;
      }
      &:nth-child(14) {
        -ms-grid-column: 3;
        -ms-grid-row: 5;
      }
      &:nth-child(15) {
        -ms-grid-column: 5;
        -ms-grid-row: 5;
      }
    }

    &.c-gap {
      grid-column-gap: 2rem;
      -ms-grid-columns: 6fr 2rem 6fr 2rem 6fr;

      > * {
        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }
        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }
        &:nth-child(3) {
          -ms-grid-column: 5;
          -ms-grid-row: 1;
        }
        &:nth-child(4) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
        }
        &:nth-child(5) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }
        &:nth-child(6) {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
        }
        &:nth-child(7) {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }
        &:nth-child(8) {
          -ms-grid-column: 3;
          -ms-grid-row: 3;
        }
        &:nth-child(9) {
          -ms-grid-column: 5;
          -ms-grid-row: 3;
        }
        &:nth-child(10) {
          -ms-grid-column: 1;
          -ms-grid-row: 4;
        }
        &:nth-child(11) {
          -ms-grid-column: 3;
          -ms-grid-row: 4;
        }
        &:nth-child(12) {
          -ms-grid-column: 5;
          -ms-grid-row: 4;
        }
        &:nth-child(13) {
          -ms-grid-column: 1;
          -ms-grid-row: 5;
        }
        &:nth-child(14) {
          -ms-grid-column: 3;
          -ms-grid-row: 5;
        }
        &:nth-child(15) {
          -ms-grid-column: 5;
          -ms-grid-row: 5;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .five-c-layout {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-child(6) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 5;
        -ms-grid-row: 2;
      }
      &:nth-child(9) {
        -ms-grid-column: 7;
        -ms-grid-row: 2;
      }
      &:nth-child(10) {
        -ms-grid-column: 9;
        -ms-grid-row: 2;
      }
      &:nth-child(11) {
        -ms-grid-column: 1;
        -ms-grid-row: 3;
      }
      &:nth-child(12) {
        -ms-grid-column: 3;
        -ms-grid-row: 3;
      }
      &:nth-child(13) {
        -ms-grid-column: 5;
        -ms-grid-row: 3;
      }
      &:nth-child(14) {
        -ms-grid-column: 7;
        -ms-grid-row: 3;
      }
      &:nth-child(15) {
        -ms-grid-column: 9;
        -ms-grid-row: 3;
      }
    }
  }

  .six-c-layout {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-columns: 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr 1.875rem 1fr;
    -ms-grid-rows: 1fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 5;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 7;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 9;
        -ms-grid-row: 1;
      }
      &:nth-child(6) {
        -ms-grid-column: 11;
        -ms-grid-row: 1;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .four-c-layout-2 {

    > * {
      width: 25%;
    }
  }

  .four-c-layout {
    grid-template-columns: 6fr 6fr 6fr 6fr;
    -ms-grid-columns: 6fr 6fr 6fr 6fr;

    > * {
      &:nth-child(1) {
        -ms-grid-column: 1;
        -ms-grid-row: 1;
      }
      &:nth-child(2) {
        -ms-grid-column: 2;
        -ms-grid-row: 1;
      }
      &:nth-child(3) {
        -ms-grid-column: 3;
        -ms-grid-row: 1;
      }
      &:nth-child(4) {
        -ms-grid-column: 4;
        -ms-grid-row: 1;
      }
      &:nth-child(5) {
        -ms-grid-column: 1;
        -ms-grid-row: 2;
      }
      &:nth-child(6) {
        -ms-grid-column: 2;
        -ms-grid-row: 2;
      }
      &:nth-child(7) {
        -ms-grid-column: 3;
        -ms-grid-row: 2;
      }
      &:nth-child(8) {
        -ms-grid-column: 4;
        -ms-grid-row: 2;
      }
    }

    &.c-gap {
      grid-column-gap: 2rem;
      -ms-grid-columns: 1fr 2rem 1fr 2rem 1fr 2rem 1fr;

      > * {

        &:nth-child(1) {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        &:nth-child(2) {
          -ms-grid-column: 3;
          -ms-grid-row: 1;
        }

        &:nth-child(3) {
          -ms-grid-column: 5;
          -ms-grid-row: 1;
        }

        &:nth-child(4) {
          -ms-grid-column: 7;
          -ms-grid-row: 1;
        }

        &:nth-child(5) {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
        }

        &:nth-child(6) {
          -ms-grid-column: 3;
          -ms-grid-row: 2;
        }

        &:nth-child(7) {
          -ms-grid-column: 5;
          -ms-grid-row: 2;
        }

        &:nth-child(8) {
          -ms-grid-column: 7;
          -ms-grid-row: 2;
        }
      }
    }
  }
}