.cta {
  position: relative;
  z-index: 0;

  &__overlay {
    position: relative;
    z-index: 1;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 3rem;
  }

  &__title {
    margin-top: 2rem;
    margin-bottom: 3rem;
    max-width: 500px;
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0;
      color: $white;
      text-transform: none;
    }
  }

  &__button {
    margin-bottom: -4.5rem;
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:before {
      content: "";
      background-color: rgba($secondary,0.15);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }

  &__text {
    margin-bottom: 80px;
    color: $white;
  }

  &[data-align="left"]{
    .cta__content {
      align-items: flex-start;
    }
    .cta__background:after {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4183be+0,4183be+100&1+0,0+100 */
      background: -moz-linear-gradient(left, rgba(65,131,190,1) 0%, rgba(65,131,190,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(65,131,190,1) 0%,rgba(65,131,190,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(65,131,190,1) 0%,rgba(65,131,190,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4183be', endColorstr='#004183be',GradientType=1 ); /* IE6-9 */
    }
  }

  &[data-align="right"]{
    .cta__content {
      align-items: flex-end;
      text-align: right;
    }
    .cta__background:after {
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4183be+0,4183be+100&1+0,0+100 */
      background: -moz-linear-gradient(right, rgba(65,131,190,1) 0%, rgba(65,131,190,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(right, rgba(65,131,190,1) 0%,rgba(65,131,190,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to left, rgba(65,131,190,1) 0%,rgba(65,131,190,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4183be', endColorstr='#004183be',GradientType=1 ); /* IE6-9 */
    }
  }
}