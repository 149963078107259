// Breadcrumbs

$breadcrumb-padding-y:              0.5rem;
$breadcrumb-padding-x:              0;
$breadcrumb-item-padding:           0.5rem !default;

$breadcrumb-margin-bottom:          2rem;

$breadcrumb-bg:                     transparent;
$breadcrumb-divider-color:          $gray-600 !default;
$breadcrumb-active-color:           $primary;
$breadcrumb-divider:                quote("/");

$breadcrumb-border-radius:          $border-radius !default;