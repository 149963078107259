body {
  @extend %default-font;
  @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1.8/1.5, 1.8);
  font-weight: 400;
  color: $body-color;
}

h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  margin-top: 0;
  margin-bottom: 0.25rem;
  @extend %additional-font;
  color: $blue;
  text-transform: uppercase;
}

p {
  margin-top: 0;
  margin-bottom: 1.75rem;
  &.lead {
    @include fluid-type($min_width, $max_width, (20px/1.2), 20px, 1.7/1.2, 1.7);
    font-weight: 800;
  }
}

h1,.h1 {
  margin-bottom: 0.5rem;
  @include fluid-type($min_width, $max_width, (46px/1.5), 46px, 1.2/1.2, 1.2);
  font-weight: 600;
}

h2,.h2 {
  margin-bottom: 0.5rem;
  @include fluid-type($min_width, $max_width, (34px/1.5), 34px, 1.2/1.2, 1.2);
  font-weight: 600;
}

h3,.h3 {
  @include fluid-type($min_width, $max_width, (28px/1.5), 28px, 1/1.2, 1);
  font-weight: 600;
}

h4,.h4 {
  @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1.3/1.2, 1.3);
  font-weight: 600;
}

h5,.h5 {
  @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1.5/1.2, 1.5);
  font-weight: 600;
}

h6,.h6 {
  @include fluid-type($min_width, $max_width, (22px/1.2), 22px, 1.4/1.2, 1.4);
  font-weight: 600;
}