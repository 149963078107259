.image-footer {
  position: relative;
  z-index: 0;

  &__overlay {
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas: "left-gutter footer-content right-gutter";

    display: -ms-grid;
    -ms-grid-columns: 1fr 8fr 1fr;
  }

  &__content {
    grid-column: footer-content;
    -ms-grid-column: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 3rem;

    //fix for width calculation owl carousel
    min-width: 100%;
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+30,000000+80&0+30,0.2+80,0.2+80 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 30%, rgba(0,0,0,0.2) 80%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 30%,rgba(0,0,0,0.2) 80%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 30%,rgba(0,0,0,0.2) 80%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#33000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    position: relative;
    width: 100%;
    margin-top: 5rem;
    margin-bottom: 5rem;
    text-align: center;
    overflow: hidden;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      position: relative;
      display: inline-block;
      color: $white;

      &:before, &:after {
        content: "";
        background-color: $white;
        margin: 0 1rem;
        position: absolute;
        top: 50%;
        height: 1px;
        width: 2000px;
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }
    }
  }

  &__name {
    margin-bottom: 1rem;
    @extend %additional-font;
    @include fluid-type($min_width, $max_width, (40px/1.5), 40px, 1, 1);
    font-weight: 700;
    color: $white;
  }

  &__address {
    margin-bottom: 1rem;
    @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1, 1);
    color: $white;
  }

  &__phone {
    @extend %additional-font;
    @include fluid-type($min_width, $max_width, (27px/1.5), 27px, 1, 1);
    font-weight: 700;
    color: $white;
    a {
      @extend %default-font;
      @include fluid-type($min_width, $max_width, (22px/1.5), 22px, 1, 1);
      font-weight: 400;
      color: $white;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .image-footer {
    &__overlay {

    }
  }
}