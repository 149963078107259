$white:         #fff;
$grey:          #e7e7e7;
$grey-dark:     #353535;
$black:         #000;

$blue:          #1d2851;
$yellow:        #ffde26;
$red:           #c30c26;

$primary:       $blue;
$secondary:     $yellow;

$body-color:    $grey-dark;