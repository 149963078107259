aside {
  .sidebar-item {
    + .sidebar-item {
      margin-bottom: 2rem;
    }
    .share {
      margin-bottom: 2.75rem;
      text-align: right;
    }
    ul.share {
      margin: 0.5rem 0 0 0.5rem;
      li {
        a {
          padding: 0 0.25rem;
        }
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  aside {
    .sidebar-item {
      + .sidebar-item {
        margin-bottom: 4rem;
      }
    }
  }
}