body {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
}

.sidebar {
  position: relative;
}

.site-header {
  z-index: 2;
}

.site-content {
  img {
    width: 100%!important;
    height: auto!important;
  }

  a:not(.page-link):not(.btn) {
    color: $yellow;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 1px solid;
  }

  ul:not(.specs):not(.pagination) {
    padding-left: 2.75rem;
    margin-top: 0;
    margin-bottom: 1.75rem;
    list-style: none;

    li {
      position: relative;
      &:before {
        position: absolute;
        content: "·";
        margin-left: -1.25rem;
        color: $body-color;
        font-weight: 700;
      }
    }
  }

  table, .table {
    border-spacing: 0;
  }

  &__date {
    margin-bottom: 1rem;
  }
}

.sidebar-item {
  table, .table {
    border-spacing: 0;
  }
}

.badge {
  background-color: $black;
  display: inline-block;
  padding: 0.25rem 0.5rem;
  @extend %additional-font;
  @include fluid-type($min_width, $max_width, (27px/1.5), 27px, 1/1.5, 1);
  color: $white;
  font-weight: 600;
  text-transform: uppercase;

  &--blue {
    background-color: $primary;
  }

  &--yellow {
    background-color: $secondary;
    color: $primary;
  }
}

#page-wrapper {
  transition: 0.3s;
  &:before {
    content: "";
    background-color: rgba($blue, 0.95);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }
}

.bg-progressive {
  background-color: #ddd;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;

  .background-image-small {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 1;
    filter: blur(50px);
  }

  .background-image-large {
    background-size: cover;
    background-position: center;
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s linear;
  }

  .loaded {
    opacity: 1;
  }
}

.padding-top--normal {
  padding-top: 1rem;
}

.padding-bottom--normal {
  padding-bottom: 1rem;
}

.padding-top--large {
  padding-top: 2rem;
}

.padding-bottom--large {
  padding-bottom: 2rem;
}

.padding-top--larger {
  padding-top: 3rem;
}

.padding-bottom--larger {
  padding-bottom: 3rem;
}

.margin-top--normal {
  margin-top: 1rem;
}

.margin-bottom--normal {
  margin-bottom: 1rem;
}

.margin-offset--normal {
  margin-top: -1rem;
}

.margin-top--large {
  margin-top: 2rem;
}

.margin-bottom--large {
  margin-bottom: 2rem;
}

.margin-top--larger {
  margin-top: 3rem;
}

.margin-bottom--larger {
  margin-bottom: 3rem;
}

main {
  display:grid;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-areas:
          "left-gutter article right-gutter";

  display: -ms-grid;
  -ms-grid-columns: 1fr 8fr 1fr;

  article {
    grid-column: article;
    -ms-grid-column: 2;
  }
}

header {
  position: relative;
}

.layout-sidebar-right {
  article {
    header {
      grid-column: header;
      -ms-grid-column: 1;
      -ms-grid-column-span: 3;
    }

    section {
      grid-column: section;
      -ms-grid-column: 1;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    aside {
      grid-column: aside;
      -ms-grid-column: 3;
      -ms-grid-row: 2;
    }
  }
}

.layout-sidebar-left-right {
  article {
    header {
      grid-column: header;
      -ms-grid-column: 1;
      -ms-grid-column-span: 5;
    }

    .sidebar {
      grid-column: sidebar;
      -ms-grid-column: 1;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    section {
      grid-column: section;
      -ms-grid-column: 3;
      -ms-grid-row: 2;

      margin-bottom: 2rem;
    }

    aside {
      grid-column: aside;
      -ms-grid-column: 5;
      -ms-grid-row: 2;
    }
  }
}

@include media-breakpoint-up(md) {
  .padding-top--normal {
    padding-top: 4rem;
  }

  .padding-bottom--normal {
    padding-bottom: 4rem;
  }

  .padding-top--large {
    padding-top: 6rem;
  }

  .padding-bottom--large {
    padding-bottom: 6rem;
  }

  .padding-top--larger {
    padding-top: 9rem;
  }

  .padding-bottom--larger {
    padding-bottom: 9rem;
  }

  .margin-top--normal {
    margin-top: 4rem;
  }

  .margin-bottom--normal {
    margin-bottom: 4rem;
  }

  .margin-offset--normal {
    margin-top: -4rem;
  }

  .margin-top--large {
    margin-top: 6rem;
  }

  .margin-bottom--large {
    margin-bottom: 6rem;
  }

  .margin-offset--large {
    margin-top: -6rem;
  }

  .margin-top--larger {
    margin-top: 9rem;
  }

  .margin-bottom--larger {
    margin-bottom: 9rem;
  }

  .margin-offset--larger {
    margin-top: -9rem;
  }

  .layout--max-width {
    margin: 0 auto;
    max-width: 80%;
  }

  .layout-small {
    grid-template-columns: 5fr 8fr 5fr;
    -ms-grid-columns: 5fr 8fr 5fr;
  }

  main {
    //margin-bottom: 4rem;
  }

  .layout-sidebar-right {
    article {
      display: grid;
      grid-template-columns: 7fr 5fr;
      grid-column-gap: 4rem;
      grid-template-areas:
              "header header"
              "section aside";

      display: -ms-grid;
      -ms-grid-columns: 7fr 4rem 5fr;

      section {
        margin-bottom: 0;
      }
    }
  }

  .layout-sidebar-left-right {
    article {
      display: grid;
      grid-template-columns: 3fr 6fr 3fr;
      grid-column-gap: 2rem;
      grid-template-areas:
              "header header header"
              "sidebar sidebar sidebar"
              "section section aside";

      display: -ms-grid;
      -ms-grid-columns: 3fr 2rem 6fr 2rem 3fr;

      section {
        margin-bottom: 0;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .layout-sidebar-left-right {
    article {
      grid-template-areas:
              "header header header"
              "sidebar section aside";
    }
  }
}

@include media-breakpoint-up(xl) {
  .layout-sidebar-right {
    article {
      grid-template-columns: 8fr 4fr;
      grid-column-gap: 4rem;

      -ms-grid-columns: 8fr 4rem 4fr;

    }
  }

  .layout-sidebar-left-right {
    article {
      grid-template-columns: 2fr 8fr 3fr;
      grid-column-gap: 4rem;

      -ms-grid-columns: 2fr 4rem 8fr 4rem 3fr;

    }
  }
}

//text block
.textblock {
  padding: 2rem 0;
  color: $blue;
  text-align: center;
  border: 1px solid rgba($blue,0.2);

  strong {
    margin-right: 0.75rem;
    @extend %additional-font;
    @include fluid-type($min_width, $max_width, (30px/1.5), 30px, 1.3/1.5, 1.3);
    font-weight: 600;
    text-transform: uppercase;
  }
}

//product
.product {
  position: relative;
  display: block;
  color: $blue;

  &__image {
    position: relative;
    margin-bottom: 1.5rem;

    @extend .witteveen-icons;
    @extend .witteveen-icons-search;

    &:before {
      background-color: rgba($primary,0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include fluid-type($min_width, $max_width, (40px/1.5), 40px, 0.4/1.5, 0.4);
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
      transition: 0.7s cubic-bezier(0.49, 1, 0.22, 1) 0ms;
      opacity: 0;
    }
  }

  &__content {
    ul {
      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}
a.product {
  margin-bottom: 2rem;
  text-decoration: none;

  &:hover {
    .product__image:before {
      opacity: 1;
    }
  }
}