.quicklink {
  position: relative;
  display: block;
  z-index: 0;

  &__overlay {
    position: relative;
    min-height: 200px;
    z-index: 1;

    &:before {
      content: "";
      background-color: rgba($primary,0.8);
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @include fluid-type($min_width, $max_width, (40px/1.5), 40px, 0.4/1.5, 0.4);
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: -1;
      transition: 0.7s cubic-bezier(0.49, 1, 0.22, 1) 0ms;
      opacity: 0;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 2rem;
  }

  &__title {
    text-align: right;
    border-bottom: 2px solid $white;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0;
      color: $white;
      line-height: 1;
      @include transition(0.3s);
    }

    i {
      display: inline-block;
      margin-right: -1rem;
      color: $secondary;
      vertical-align: middle;
    }
  }

  &__background {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+40&0.65+0,0+40 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 0%, rgba(0,0,0,0) 40%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 40%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 40%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.5;
    }
  }

  &__text {
    margin-bottom: 80px;
    color: $white;
  }
}

a.quicklink {
  color: $blue;
  text-decoration: none;
  @include transition(0.3s);

  &:hover{
    .quicklink__overlay {
      &:before {
        opacity: 1;
      }
    }
    .quicklink__title {
      h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        transform: translateX(-1rem);
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .quicklink {
    &__overlay {
      min-height: 275px;
    }
  }
}

@include media-breakpoint-up(xxl) {
  .quicklink {
    &__overlay {
      min-height: 345px;
    }
  }
}