.breadcrumb {
  margin-top: 0;

  li {
    @extend .breadcrumb-item;
    display: inline;
    @include fluid-type($min_width, $max_width, (18px/1.5), 18px, 1.8/1.5, 1.8);
    color: $body-color!important;
    font-weight: 400!important;

    a {
      color: $body-color!important;
      font-weight: 400!important;
    }

    &.last {
      @extend .active;
      &:after {
        content: "";
        background-color: $body-color;
        height: 1px;
        width: calc(100% - 1rem);
        display: block;
        margin-left: 1rem;
        margin-top: -0.35rem;
      }
    }

    &:not(.last) {
      a {
        border-bottom: none !important;
      }
    }
    &:before {
      vertical-align: middle;
    }
  }
}
