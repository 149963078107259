.blog {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid rgba($blue,0.2);

  &__image {
    width: 35%;
  }

  &__content {
    margin: 1rem;

    @include limit-lines($min_width, $max_width, (22px/1.5), 22px, 1.8/1.5, 1.8, 2);
    font-weight: 400;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      text-transform: none;
    }
  }
}

a.blog {
  position: relative;
  margin-bottom: 2rem;
  padding-right: 3.5rem;
  color: $blue;
  text-decoration: none;
  @include transition(0.3s);

  @extend .witteveen-icons;
  @extend .witteveen-icons-keyboard_arrow_right;

  &:before {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    @include fluid-type($min_width, $max_width, (50px/1.5), 50px, 0.4/1.5, 0.4);
    transform: translateY(-50%);
  }

  &:hover{
    background-color: $grey;
  }
}

.blog-list {
  .blog {
    &__image {
      width: 35%;
    }
    &__content {
      width: 65%;
    }
  }
}

@include media-breakpoint-up(sm) {
  .blog {
    &__image {
      width: 25%;
    }
  }
}

@include media-breakpoint-up(md) {
  .blog {
    &__image {
      width: 60%;
    }
  }

  .blog-list {
    .blog {
      &__image {
        width: 50%;
      }
      &__content {
        width: 50%;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .blog {
    &__image {
      width: 40%;
    }

    &__content {
      margin: 0 1rem;
      width: 60%;
    }
  }
}

@include media-breakpoint-up(xl) {
  .blog {
    &__image {
      width: 25%;
    }

    &__content {
      margin: 0 0 0 2rem;
    }
  }

  .blog-list {
    .blog {
      &__image {
        width: 40%;
      }
      &__content {
        width: 60%;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .blog {
    &__image {
      width: 16%;
    }
  }
  .blog-list {
    .blog {
      &__image {
        width: 35%;
      }
      &__content {
        width: 65%;
      }
    }
  }
}