.share-component {
  position: relative;
}

.share {
  &__title {
    display: inline-block;
    @include fluid-type($min_width, $max_width, (18px/1.5), 18px, 1.5, 1.5);
  }
}

ul.share {
  list-style: none;
  margin: 0 0.75rem;
  padding: 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;

  li {
    display: inline;
    padding: 0;
    text-align: center;

    &:before {
      display: none;
    }

    a {
      display: inline-block;
      padding: 0rem 0.25rem;
      @include fluid-type($min_width, $max_width, (25px/1.5), 25px, 1.5, 1.5);
      color: $primary;

      i {
        background-color: transparent;
        display: inline-block;
        margin-right: 4px;

        width: 24px;
        height: 24px;

        text-align: center;
      }

    }
  }
  &-footer {
    li {
      a {
        padding: 1rem 2rem;
        @include fluid-type($min_width, $max_width, (40px/1.5), 40px, 1.5, 1.5);
        color: $white;
      }
    }
  }
}

