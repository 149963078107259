.nav-container {
    background-color: $white;
    //height: 125px;
    width: 100%;
    z-index: 9;
    transition: 0.3s;

    .navbar {
        grid-column: navbar;
        -ms-grid-column: 2;
        position: relative;
        justify-content: space-between;
        display: flex;
        align-items: center;

        .navbar-brand {
            margin-left: 1rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 0;
            line-height: inherit;
            z-index: 0;

            img {
                max-width: 84px;
            }
        }

        .nav {
            margin-left: auto;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

ul.nav-desktop {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    z-index: 99;
    display: none;
    order: 2;

    >li {
        display: inline-block;

        >a {
            position: relative;
            display: inline-block;
            padding: 0 1rem;
            color: $blue;
            font-weight: 400;
            text-decoration: none;
            transition: 0.3s;
            @include fluid-type($min_width, $max_width, (20px/1.5), 20px, 1.8/1.5, 1.8);
        }

        &.has-subitems {
            position: relative;

            .dropdown-toggle {
                display: inline-block;
                margin-left: -1rem;
                margin-right: 1rem;
                vertical-align: top;

                i:before {
                    display: table-cell;
                    transform: rotate(90deg);
                    transition: 0.3s;
                }
            }
        }

        //mega submenu
        >.submenu {
            background-color: $white;
            position: absolute;
            top: 100%;
            left: 0;
            border-bottom: 4px solid $yellow;
            visibility: hidden;
            opacity: 0;
            transition: 0.2s;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                &.submenu-items {
                    width: 240px;

                    @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);

                    >li {
                        position: relative;
                        padding: 1rem 1rem;
                        break-inside: avoid;

                        a {
                            position: relative;
                            color: $body-color;
                            text-decoration: none;
                            transition: 0.3s;
                        }

                        &.active,
                        &:hover {
                            background-color: $yellow;

                            a {}
                        }
                    }
                }
            }
        }

        //open submenu on click
        &.show {
            >a:not(.btn) {
                color: $primary;

                &:before {
                    background-color: $primary;
                    width: 100%;
                }
            }

            .dropdown-toggle {
                i:before {
                    transform: rotate(-90deg);
                }
            }

            .submenu {
                visibility: visible;
                opacity: 1;
            }
        }

        //active item
        &.active {
            >a {
                color: $primary;

                &:before {
                    width: 100%;
                }
            }
        }
    }
}

//open submenu on hover
body.is_desktop {
    ul.nav-desktop {
        >li {

            //open submenu
            &:hover {
                >a:not(.btn) {
                    color: $primary;

                    &:before {
                        background-color: $primary;
                        width: 100%;
                    }
                }

                .dropdown-toggle {
                    i:before {
                        transform: rotate(-90deg);
                    }
                }

                .submenu {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

#nav-secundary {
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;

    >li {
        display: inline-block;

        >a {
            background-color: $grey;
            display: inline-block;
            @extend %additional-font;
            @include fluid-type($min_width, $max_width, (32px/1.5), 32px, 1.2/1.5, 1.2);
            font-weight: 700;

            span {
                display: block;
                @extend %default-font;
                @include fluid-type($min_width, $max_width, (20px/1.5), 20px, 1.8/1.5, 1.8);
                font-weight: 400;
            }

            &:hover {
                background-color: $secondary;
            }
        }
    }
}

ul.nav-categories {
    position: absolute;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    z-index: 1;
    display: none;
    order: 2;

    >li {
        position: relative;
        background-color: $secondary;
        display: inline-block;
        white-space: nowrap;

        span {
            display: inline-block;
            margin-left: -1rem;
            margin-right: 1rem;
            vertical-align: top;

            i {
                @include fluid-type($min_width, $max_width, (50px/1.5), 50px, 1.4/1.5, 1.4);

                &:before {
                    display: table-cell;
                    transform: rotate(90deg);
                    transition: 0.3s;
                }
            }
        }

        .custom-arrow {
            display: inline-flex;
        }

        >a {
            position: relative;
            display: inline-block;
            padding: 1rem;

            @extend %additional-font;
            @include fluid-type($min_width, $max_width, (28px/1.5), 28px, 1.4/1.5, 1.4);
            color: $blue;
            text-transform: uppercase;
            text-decoration: none;
            transition: 0.3s;
        }

        &.has-subitems {
            >a {
                @extend .witteveen-icons;
                @extend .witteveen-icons-keyboard_arrow_right;

                &:before {
                    @include fluid-type($min_width, $max_width, (46px/1.5), 46px, 0.85/1.5, 0.85);
                    float: right;
                    transform: rotate(90deg);
                    transition: 0.3s;
                }
            }
        }

        .custom-arrow {
            display: inline-flex;
        }

        //mega submenu
        >ul {
            background-color: $white;
            padding: 0;
            position: absolute;
            top: 100%;
            left: 0;
            min-width: 100%;
            list-style: none;
            //padding: 6rem 10vw 2rem 10vw;
            margin: 0;
            visibility: hidden;
            opacity: 0;
            transition: 0.2s;
            z-index: -1;
            border-bottom: 4px solid $yellow;

            display: block;

            box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1);

            >li {
                position: relative;
                //padding: 0.5rem 1rem;
                break-inside: avoid;
                width: 100%;

                a {
                    position: relative;
                    display: block;
                    padding: 1rem 1rem;
                    @include fluid-type($min_width, $max_width, (20px/1.25), 20px, 1, 1);
                    color: $body-color;
                    text-decoration: none;
                    transition: 0.3s;
                }

                &.active,
                &:hover {
                    background-color: $secondary;

                    a {
                        color: $blue;

                        &:before {
                            right: 0rem;
                        }
                    }
                }

                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;

                    li {
                        a {
                            padding-right: 2.25rem;
                        }
                    }
                }
            }
        }

        //active item
        &.active {
            >a {
                color: $primary;

                &:before {
                    width: 100%;
                }
            }
        }

        //open submenu on click
        &.show {
            background-color: darken($secondary, 10%);

            >a {
                &:before {
                    transform: rotate(-90deg);
                }
            }

            span {
                i:before {
                    transform: rotate(-90deg);
                }
            }

            >ul {
                visibility: visible;
                opacity: 1;
            }
        }

        &[data-position="left"] {
            ul {
                left: auto;
                right: 0;
            }
        }
    }
}

//open submenu on hover
body.is_desktop {
    ul.nav-categories {
        >li {

            //open submenu
            &:hover {
                background-color: darken($secondary, 10%);

                >a {
                    &:before {
                        transform: rotate(-90deg);
                    }
                }

                span {
                    i:before {
                        transform: rotate(-90deg);
                    }
                }

                >ul {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }
}

@include media-breakpoint-up(sm) {
    .nav-container {
        .navbar {
            .nav {
                padding-right: 1.5rem;
                padding-left: 1.5rem;
            }

            .navbar-brand {
                img {
                    max-width: 122px;
                }
            }
        }
    }

    ul.nav-desktop {
        li {
            >a {
                &:not(.btn) {}
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    body {
        .nav-container {
            .navbar {
                .navbar-brand {
                    margin-left: 1.5rem;
                    //img {
                    //  max-width: 175px;
                    //}
                }
            }
        }
    }

    ul.nav-desktop,
    #nav-secundary {
        >li {
            &.has-subitems {
                position: relative;

                >a {
                    &:before {
                        width: 100%;
                    }
                }
            }
        }
    }

    .nav-container {
        //position: fixed;
        //left: 0;

        &.sticky {
            box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.1);

            .nav-topbar {
                height: 0;
                opacity: 0;
            }
        }
    }

    ul.nav-desktop {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    ul#nav-secundary {
        >li {
            a {
                padding: 1rem 1rem;
            }
        }
    }

    ul.nav-categories {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}

@include media-breakpoint-up(xl) {

    body {
        .nav-container {
            display: grid;
            grid-template-columns: 1fr 9fr;
            grid-template-areas: "left-gutter navbar";

            display: -ms-grid;
            -ms-grid-columns: 1fr 9fr;

            .navbar {
                .navbar-brand {
                    margin-left: 0;

                    img {
                        max-width: 195px;
                    }
                }
            }
        }
    }

    ul#nav-secundary {
        >li {
            a {
                padding: 1.5rem 2.5rem;
            }
        }
    }
}

@include media-breakpoint-up(xxl) {
    body {
        .nav-container {
            .navbar {
                .navbar-brand {
                    img {
                        max-width: 375px;
                    }
                }
            }
        }
    }

    ul#nav-secundary {
        >li {
            a {
                padding: 1.5rem 4.5rem;
            }
        }
    }
}