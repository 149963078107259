.image-header {
  position: relative;
  z-index: -1;

  &-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display:grid;
    grid-template-columns: 1fr 8fr 1fr;
    grid-template-areas:
            "left-gutter header-content right-gutter";

    display: -ms-grid;
    -ms-grid-columns: 1fr 8fr 1fr;

    @include media-breakpoint-up(sm) {
      //max-width: 75%;
    }
  }

  &__content {
    grid-column: header-content;
    -ms-grid-column: 2;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 3.5rem;

    min-width: 100%;
  }

  &__background {
    position: relative;
    height: 12vw;
    min-height: 240px;

    &:after {
      content: "";
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+60,000000+90&0+60,0.4+90 */
      background: -moz-linear-gradient(top, rgba(0,0,0,0) 60%, rgba(0,0,0,0.4) 90%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(0,0,0,0) 60%,rgba(0,0,0,0.4) 90%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(0,0,0,0) 60%,rgba(0,0,0,0.4) 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0 ); /* IE6-9 */
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__banner {
    width: 100%;
    @include media-breakpoint-up(sm) {
      position: absolute;
      right: 0;
      top: 50%;
      max-width: 25%;
      transform: translateY(-50%);
    }
    @include media-breakpoint-up(lg) {
      top: calc(50% + 30px);
    }
    img {
      display: block;
      object-fit: cover;
      width: 100%;
      max-width: none;
      max-height: none;
    }
  }

  &__text {
    margin-bottom: 80px;
    color: $white;
  }

  &__title {
    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      margin-bottom: 0.25rem;
      color: $white;
    }
  }

  &__usp {
    padding-top: 0.75rem;
    border-top: 2px solid #fff;
    .owl-usp-carousel {
      padding: 0;
      margin: 0;
      list-style: none;
      color: $white;
      .item {
        margin-right: 1.5rem;
        margin-bottom: 0.5rem;
        display: block;
        i {
          margin-right: .75rem;
          @include fluid-type($min_width, $max_width, (34px/1.5), 34px, 1, 1);
          color: $secondary;
          vertical-align: middle;
        }
      }
    }
  }

  //specific styling for homepage
  &--home {
    .image-header__background {
      height: 38vw;
      min-height: 300px;
    }
  }
}

.bg-image-slider {
  .bg-progressive {
    opacity: 0;
    transition: 1s cubic-bezier(.49,1,.22,1) 0ms;
    &.active {
      opacity: 1;
    }
  }
}

@include media-breakpoint-up(sm) {

}

@include media-breakpoint-up(md) {

}

@include media-breakpoint-up(lg) {

}

@include media-breakpoint-up(xl) {
  .image-header {
    &__usp {
      ul {
        li {
          display: inline-block;
          margin-bottom: 0;
        }
      }
    }
  }
}
