.btn {
  display: inline-block;
  padding: 1rem 1rem;
  @extend %additional-font;
  @include fluid-type($min_width, $max_width, (25px/1.5), 25px, 1/1.5, 1);
  color: $white;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  border: none;
  cursor: pointer;

  i {
    display: inline-block;
    @include fluid-type($min_width, $max_width, (42px/1.5), 42px, 0.4/1.5, 0.4);
    vertical-align: middle;
  }

  &-default {
    background-color: $black;

    &:hover {
      background-color: darken($black,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-primary {
    background-color: $primary;

    &:hover {
      background-color: darken($primary,5%);
      color: $white;
      text-decoration: none;
    }
  }

  &-secondary {
    background-color: $secondary;
    color: $primary;

    &:hover {
      background-color: darken($secondary,5%);
      color: $white;
      text-decoration: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .btn {
    padding: 1.25rem 3rem;
  }
}