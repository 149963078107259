.slider {
  margin-bottom: 5rem;
  width: 85%;

  .item {
  }

  .figure-progressive {
    background-color: transparent;
  }
}

@include media-breakpoint-up(sm) {
  .slider {
    .item {

    }
  }
}

@include media-breakpoint-up(md) {
  .slider {
    .item {

    }
  }

}

@include media-breakpoint-up(lg) {
  .slider {
    .item {

    }
  }
}

@include media-breakpoint-up(xl) {
  .slider {
    .item {

    }
  }
}