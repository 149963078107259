.section {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  grid-template-areas:
          "left-gutter section__header right-gutter"
          "left-gutter section__body right-gutter";
  display: -ms-grid;
  -ms-grid-columns: 1fr 8fr 1fr;

  &__header {
    padding-bottom: 2rem;

    grid-area: section__header;
    -ms-grid-column: 2;
    -ms-grid-row: 1;
    -ms-grid-column-span: 1;
  }

  &__title {
    margin-bottom: 0.5rem;
    @include fluid-type($min_width, $max_width, (47.5px/1.6), 47.5px, 0.9, 0.9);
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    overflow: hidden;

    h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
      position: relative;
      display: inline-block;

      &:before, &:after {
        content: "";
        background-color: $blue;
        margin: 0 1rem;
        position: absolute;
        top: 50%;
        height: 1px;
        width: 2000px;
      }

      &:before {
        right: 100%;
      }

      &:after {
        left: 100%;
      }
    }
  }

  &__subtitle {
    @include fluid-type($min_width, $max_width, (32.5px/1.6), 32.5px, 0.9, 0.9);
    font-weight: 300;
  }

  &__link {
    text-align: right;
    a {
      position: relative;
      @include fluid-type($min_width, $max_width, (22.5px/1.6), 22.5px, 1.4, 1.4);
      color: $secondary;
      font-weight: 700;
      text-decoration: none;
      &:before {
        content: "›";
        margin-right: 1.5rem;
        @include fluid-type($min_width, $max_width, (40px/1.6), 40px, 1, 1);
        font-weight: 600;
        vertical-align: bottom;
      }
    }
  }

  &__content {
    display: grid;
    grid-template-columns: auto auto;
  }

  &__body {
    grid-area: section__body;

    -ms-grid-column: 2;
    -ms-grid-row: 2;
    min-width: 100%
  }
}