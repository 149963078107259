.gallery {
    @include clearfix;
    overflow: hidden;
    margin: 0 -5px;

    a {
        display: inline-block;
        float: left;
        position: relative;
        width: 20%;
        padding: 5px;
        text-decoration: none;
        border: none!important;

        &:after {
            content: "\E00D";
            font-family: "witteveen-icons";
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            line-height: 1;
            text-decoration: inherit;
            text-rendering: optimizeLegibility;
            text-transform: none;
            -moz-osx-font-smoothing: grayscale;
            -webkit-font-smoothing: antialiased;

            font-smoothing: antialiased;
            background-color: rgba($primary, 0.75);
            position: absolute;
            top: 5px;
            left: 5px;
            height: calc(100% - 10px);
            width: calc(100% - 10px);

            color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;

            -webkit-backface-visibility: hidden;
            backface-visibility: hidden;

            -webkit-transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);
            transition: all 300ms 0ms cubic-bezier(0.6, -0.3, 0.7, 0.05);

            opacity: 0;
        }

        &:hover {
            &:after {
                font-size: calculateRem(65px);
                opacity: 1;
            }
        }

        &:focus {
            outline: none;
        }
    }
}

.gallery {
    &.one-image {
        a {
            width: 100%;

            @include media-breakpoint-up(sm) {
                width: 50%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.two-image {
        a {
            width: 50%;
        }
    }
    &.three-image {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 33%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }
        }
    }
    &.more {
        a {
            width: 50%;

            @include media-breakpoint-up(sm) {
                width: 33%;
            }

            @include media-breakpoint-up(md) {
                width: 25%;
            }

            @include media-breakpoint-up(lg) {
                width: 20%;
            }
        }
    }
}